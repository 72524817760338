import React from "react";
import logoImg from "../img/logo_emepag.svg";
import partnersImg from "../img/partners.png";

import facebookIcon from "../img/facebook-icon.png";
import instagramIcon from "../img/instagram-icon.png";
import linkedinIcon from "../img/linkedin-icon.png";

import creditCardBanner from "../img/credit-card-banner.svg";
import creditCardLogos from "../img/credit-card-logos.png";
import rotadomar from "../img/rotadomar.png";
import ReactDOM from "react-dom"
import AnchorLink from "react-anchor-link-smooth-scroll"

import Lottie from "react-lottie";
import showcaseAnimation from "../animations/showcase.json";
import signupAnimation from "../animations/sign-up-banner.json";
import securityAnimation from "../animations/security.json";
import CookieAlert from "../components/CookieAlert";
import Footer from "../components/Footer";
import Modals from "../components/Modals";

const lottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function Home() {
  return (
    <div className="hide-overflow">
      {/* <!--Modal Terms--> */}

      <Modals />

      {/*<!--Navbar-->*/}
      <nav className="navbar navbar-expand-xl bg-white navbar-light px-2 px-lg-5 py-3 mb-2 mb-lg-5">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              alt=""
              width="150"
              height="53"
              className="d-inline-block align-top img-fluid"
              src={logoImg}
            />
          </a>
          <button
            className="navbar-toggler ms-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navmenu"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navmenu"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <AnchorLink href="#cases" className="nav-link  px-4 roboto text-black">
                  Cases
                </AnchorLink>
              </li>
              <li className="nav-item">
                <AnchorLink href="#fees" className="nav-link  px-4 roboto text-black">
                  Taxas
                </AnchorLink>
              </li>

              <li className="nav-item">
                <a href="/contato" className="nav-link  px-4 roboto text-black">
                  Contato
                </a>
              </li>
              <li className="nav-item d-xl-none">
                <a
                  href="https://painel.emepag.com.br/login"
                  className="nav-link text-primary fw-bold px-4  roboto"
                >
                  Entrar
                </a>
              </li>
              <li className="nav-item d-xl-none">
                <a
                  href="/cadastro"
                  className="btn btn-primary  rounded-pill px-4 mt-2"
                >
                  Crie sua conta grátis
                </a>
              </li>
            </ul>
          </div>
          <div className="d-none d-xl-block align-items-end">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  href="https://painel.emepag.com.br/login"
                  className="nav-link text-primary fw-bold px-5 roboto"
                >
                  Entrar
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/cadastro"
                  className="btn btn-primary  rounded-pill px-5 fs-6"
                >
                  Crie sua conta grátis
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/*<!--Showcase-->*/}

      <section className="p-2 p-lg-5 text-center text-sm-start">
        <div className="container">
          <div className="row">
            <div className="col-md-5 p-3 pt-lg-5">
              <h1 className="text-info fw-bold">
                Proteja suas vendas com seguro de fraude
              </h1>
              <p className="smaller-font roboto">
                Envie links de pagamento em qualquer canal digital sem
                preocupação com fraude ou chargeback
              </p>
              <a
                href="/cadastro"
                className="btn btn-primary text-light  rounded-pill px-5 mt-2"
              >
                Crie sua conta grátis
              </a>
            </div>
            <div className="col-md-7 mt-4 mt-md-0 z-0 ">
              <Lottie
                options={{ ...lottieOptions, animationData: showcaseAnimation }}
              />
            </div>
          </div>
        </div>
      </section>

      {/*<!--Text-->*/}

      <section className="px-2 px-lg-5 mt-4 mt-lg-1 mb-5 mb-lg-2 text-center text-primary">
        <div className="container">
          <h2>A proteção definitiva para o seu negócio</h2>
        </div>
      </section>

      {/*<!--Cards-->*/}

      <section className="py-2 px-4 p-lg-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-md-4 ">
              <div className="custom-card h-100">
                <div className="custom-card-body p-4 justify-content-start">
                  <div className="features-card-bar ms-2 my-2 bg-info"></div>
                  <div className="text-start mx-2 my-3 justify-content-center ps-4">
                    <h4 className="card-title mb-3 text-info">
                      Venda protegida
                    </h4>
                    <p className="card-text even-smaller-font roboto">
                      Cobertura de chargeback por fraude comprovada
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="custom-card h-100">
                <div className="custom-card-body p-4 justify-content-start">
                  <div className="features-card-bar ms-2 my-2 bg-info"></div>
                  <div className="text-start mx-2 my-3 justify-content-center ps-4">
                    <h4 className="card-title mb-3 text-info">
                      Análise automática
                    </h4>
                    <p className="card-text even-smaller-font roboto">
                      Inteligência artificial e pessoas juntas para mais
                      eficiência
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="custom-card h-100">
                <div className="custom-card-body p-4 d-flex justify-content-start">
                  <div className="features-card-bar ms-2 my-2 bg-info"></div>
                  <div className="text-start mx-2 my-3 justify-content-center ps-4">
                    <h4 className="card-title mb-3 text-info">Venda mais</h4>
                    <p className="card-text even-smaller-font roboto">
                      Aumente suas vendas com uma taxa de conversão de até 98.5%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<!--Partners-->*/}

      <section className="py-5 px-2 px-lg-5" id="cases">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <h2 className="text-center text-md-start text-primary mb-5 ">
                Empresas que ajudamos a vender com mais segurança
              </h2>
              <img
                src={partnersImg}
                className="img-fluid mb-2 mb-lg-0"
                alt=""
              />
            </div>
            <div className="col-md-7 mt-3 mt-md-0">
              <Lottie
                options={{ ...lottieOptions, animationData: securityAnimation }}
              />
            </div>
          </div>
        </div>
      </section>

      {/*<!--Parners banners-->*/}
      {/*<!--<section className="p-2 p-lg-5">
        <div className="container">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div
                  className="
                  container
                  p-3
                  text-center
                  d-block
                    w-100
                  partner-background
                "
                >
                  <div
                    className="
                    mx-md-5
                    row
                    align-items-center
                    justify-content-between
                    py-md-4
                    mt-md-1
                  "
                  >
                    <div className="col-md-4 p-2 ps-md-5">
                      <img
                        src={rotadomar}
                        className="img-fluid partner-img"
                        alt=""
                      />
                    </div>
                    <div
                      className="
                      col-md-8
                      p-2
                      ps-md-5
                      pe-md-2
                      mb-3
                      text-center text-md-start
                    "
                    >
                      <h4
                        className="
                        px-4
                        text-primary
                        mb-2
                        me-md-5
                        text-start
                        justifying
                      "
                      >
                        ”A Emepag tem sido de extrema importância para nós
                        porque nos deu mais tranquilidade em nossas vendas”
                      </h4>
                      <h4 className="text-info mb-2 px-md-4">Arnaldo Xavier</h4>
                      <span className="text-dark smaller-font roboto px-md-4">
                        Diretor - Rota do Mar
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div
                  className="
                  container
                  p-3
                  text-center
                  d-block
                    w-100
                  partner-background
                "
                >
                  <div
                    className="
                    mx-md-5
                    row
                    align-items-center
                    justify-content-between
                    py-md-4
                    mt-md-1
                  "
                  >
                    <div className="col-md-4 p-2 ps-md-5">
                      <img
                        src={rotadomar}
                        className="img-fluid partner-img"
                        alt=""
                      />
                    </div>
                    <div
                      className="
                      col-md-8
                      p-2
                      ps-md-5
                      pe-md-2
                      mb-3
                      text-center text-md-start
                    "
                    >
                      <h4
                        className="
                        px-4
                        text-primary
                        mb-2
                        me-md-5
                        text-start
                        justifying
                      "
                      >
                        ”A Emepag tem sido de extrema importância para nós
                        porque nos deu mais tranquilidade em nossas vendas”
                      </h4>
                      <h4 className="text-info mb-2 px-md-4">Arnaldo Xavier</h4>
                      <span className="text-dark smaller-font roboto px-md-4">
                        Diretor - Rota do Mar
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div
                  className="
                  container
                  p-3
                  text-center
                  d-block
                    w-100
                  partner-background
                "
                >
                  <div
                    className="
                    mx-md-5
                    row
                    align-items-center
                    justify-content-between
                    py-md-4
                    mt-md-1
                  "
                  >
                    <div className="col-md-4 p-2 ps-md-5">
                      <img
                        src={rotadomar}
                        className="img-fluid partner-img"
                        alt=""
                      />
                    </div>
                    <div
                      className="
                      col-md-8
                      p-2
                      ps-md-5
                      pe-md-2
                      mb-3
                      text-center text-md-start
                    "
                    >
                      <h4
                        className="
                        px-4
                        text-primary
                        mb-2
                        me-md-5
                        text-start
                        justifying
                      "
                      >
                        ”A Emepag tem sido de extrema importância para nós
                        porque nos deu mais tranquilidade em nossas vendas”
                      </h4>
                      <h4 className="text-info mb-2 px-md-4">Arnaldo Xavier</h4>
                      <span className="text-dark smaller-font roboto px-md-4">
                        Diretor - Rota do Mar
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div
                  className="
                  container
                  p-3
                  text-center
                  d-block
                    w-100
                  partner-background
                "
                >
                  <div
                    className="
                    mx-md-5
                    row
                    align-items-center
                    justify-content-between
                    py-md-4
                    mt-md-1
                  "
                  >
                    <div className="col-md-4 p-2 ps-md-5">
                      <img
                        src={rotadomar}
                        className="img-fluid partner-img"
                        alt=""
                      />
                    </div>
                    <div
                      className="
                      col-md-8
                      p-2
                      ps-md-5
                      pe-md-2
                      mb-3
                      text-center text-md-start
                    "
                    >
                      <h4
                        className="
                        px-4
                        text-primary
                        mb-2
                        me-md-5
                        text-start
                        justifying
                      "
                      >
                        ”A Emepag tem sido de extrema importância para nós
                        porque nos deu mais tranquilidade em nossas vendas”
                      </h4>
                      <h4 className="text-info mb-2 px-md-4">Arnaldo Xavier</h4>
                      <span className="text-dark smaller-font roboto px-md-4">
                        Diretor - Rota do Mar
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>-->*/}

      {/*<!--Emepag Banners-->*/}

      <section className="p-2 p-lg-5" id="fees">    {/*<!--It was p-4 before removing carousel-->*/}
        <div className="container mb-5">
          <div className="row mt-0"> {/*<!--It was mt-5 mt-lg-0 before removing carousel-->*/}
            <div className="col-lg mb-5 mb-md-0 me-0 me-md-3">
              <h1 className="text-center text-lg-start fw-bold mb-2 mb-lg-0">
                <span className="text-primary">Uma única taxa</span>
                <span className="text-info"> Sem surpresas!</span>
              </h1>
              <p className="small-text roboto text-center text-lg-start mb-4 mb-md-5">
                Parcele em até 12x e pague uma única taxa sob a venda. Simples
                assim!
              </p>
              <div className="row custom-card fees-container py-4 px-2 p-md-4">
                <div
                  className="
                  col-6
                  d-flex
                  justify-content-center
                  align-items-center
                  mb-3 mb-lg-2
                "
                >
                  <div className="bg-secondary fees-card p-3 text-center mb-md-4">
                    <h5 className="text-primary fw-bold mb-1 text-proportion">
                      Crédito <span className="d-none d-lg-inline">em até 12x</span>{" "}
                      
                    </h5>
                    <h4 className="fw-bold mb-1 roboto">5,29%</h4>
                    <span className="smaller-font roboto text-proportion">
                      Por transação aprovada
                    </span>
                  </div>
                </div>
                <div
                  className="
                  col-6
                  d-flex
                  justify-content-center
                  align-items-center
                  mb-3 mb-lg-2
                "
                >
                  <div className="bg-secondary fees-card p-3 text-center mb-md-4">
                    <h5 className="text-primary fw-bold mb-1 text-proportion">
                      Débito
                    </h5>
                    <h4 className="fw-bold mb-1 roboto">5,29%</h4>
                    <span className="smaller-font roboto text-proportion">
                      Por transação aprovada
                    </span>
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <div className="bg-secondary fees-card p-3 text-center">
                    <h5 className="text-primary fw-bold mb-1 text-proportion">
                      Boleto
                    </h5>
                    <h4 className="fw-bold mb-1 roboto">R${""}3,60</h4>
                    <span className="smaller-font roboto text-proportion">
                      Por boleto pago
                    </span>
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <div className="bg-secondary fees-card p-3 text-center">
                    <h5 className="text-primary fw-bold mb-1 text-proportion">
                      PIX
                    </h5>
                    <h4 className="fw-bold mb-1 roboto">0,99%</h4>
                    <span className="smaller-font roboto text-proportion">
                      Por PIX pago
                    </span>
                  </div>
                </div>
              </div>
              <p className="text-center smaller-font roboto mt-4">
                Fatura mais de 100k por mês?
                <a className="nounderline" href="/contato">
                  <span className="text-primary fw-bold ms-2">
                    {" "}
                    Entre em contato
                  </span>
                </a>
              </p>
            </div>
            <div
              className="
              col-lg
              ms-0 ms-md-3
              justify-content-center
              align-items-center
            "
            >
              <div className="container d-flex flex-column align-items-center margin-creditcard">
                <img
                  src={creditCardBanner}
                  alt=""
                  className="img-fluid mb-2 mb-lg-3 mt-0 mt-lg-4 "
                />
                <p className="smaller-font roboto mb-3 text-center mt-2 mt-lg-0">
                  Aceite as principais bandeiras do mercado, boleto e PIX
                </p>
                <img src={creditCardLogos} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<!--Sign-up Banner-->*/}

      <section className="bg-size bg-secondary mt-5">
        <div className="container py-1 px-2 px-lg-5 pt-lg-5">
          <div className="row">
            <div className="col-md-7">
              <div className="position-img ">
                <Lottie
                  options={{ ...lottieOptions, animationData: signupAnimation }}
                />
              </div>
            </div>
            <div
              className="
              col-md-5
              align-items-center
              ps-md-5
              text-center text-md-start
              py-4 py-md-3 py-lg-2
              signup-text
            "
            >
              <h2 className="text-primary">
                Pronto para vender sem se preocupar com fraudes?
              </h2>
              <a
                href="/cadastro"
                className="btn btn-primary rounded-pill px-5 mt-4"
              >
                Crie sua conta grátis
              </a>
            </div>
          </div>
        </div>
      </section>

      {/*<!--Cookie Caution-->*/}
      <CookieAlert />

      {/*<!---->*/}

      {/*<!--Footer-->*/}

      <Footer />
    </div>
  );
}
