import React from "react";
import { useCookies } from "react-cookie";
import Modals from "./Modals";


export default function CookieAlert() {
  const [cookies, setCookie] = useCookies(["cookie"]);

  function acceptCookies() {
    setCookie("cookie", "true", { path: "/" });
  }

  if (cookies.cookie === "true") return null;

  return (
    
    <section className="p-1 p-lg-5 mt-5 fixed-bottom">
      <div className="container">
        <div
          className="
            alert
            custom-card
            alert-dismissible
            fade
            show
            text-center text-md-start
            align
          "
          role="alert"
        >
          <div className="row">
            <div className="col-md-9 text-proportion">
              <p className="roboto even-smaller-font pt-lg-2 ps-lg-3">
                Utilizamos cookies essenciais e tecnologias semelhantes de
                acordo com a nossa
                <a
                  href="#"
                  className="text-primary fw-bold nounderline"
                  data-bs-toggle="modal"
                  data-bs-target="#modalPrivacy"
                >
                  {" "}Política de Privacidade {" "}
                </a>
                e, ao continuar navegando, você concorda com estas condições.
              </p>
            </div>
            <div className="col-md-3 d-flex align-items-center justify-content-center">
              <button
                onClick={acceptCookies}
                data-bs-dismiss="alert"
                className="btn btn-primary fw-bold rounded-pill px-5"
              >
                Continuar
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
