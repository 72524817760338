import React, { useState } from "react";

import { AiOutlineMenu } from "react-icons/ai";

import logoWhite from "../img/logo-white.png";

export default function Pannel() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 992);

  return (
    <div>
      <nav
        className={` ps-4 navbar navbar-dark bg-dark navbar-expand d-flex flex-column align-item-start sidenav-pannel ${
          isSidebarOpen ? "active" : ""
        }`}
        id="sidebar"
      >
        <ul className="sidenav-text-position navbar-nav d-flex flex-column w-100">
          <li className="nav-item w-100">
            <a
              className="nav-link roboto text-white navigation-link ps-4"
              href="#"
            >
              Pagamentos
            </a>
          </li>
          <li className="nav-item w-100">
            <a
              className="nav-link roboto text-white navigation-link ps-4"
              href="#"
            >
              Lançamentos
            </a>
          </li>
          <li className="nav-item w-100">
            <a
              className="nav-link roboto text-white navigation-link ps-4"
              href="#"
            >
              Sair
            </a>
          </li>

          <li className="nav-item w-100">
            <a href="/" className="ps-4">
              <img alt="" class="img-fluid pannel-logo-position" src={logoWhite}></img>
            </a>
          </li>
        </ul>
      </nav>

      <section
        className={`my-container me-lg-5  ${
          isSidebarOpen ? "sidebar-active" : ""
        }`}
      >
        <div className="mx-3">
          {" "}
          <div
            className=" d-lg-none my-1 p-2 btn pannel-menu-btn rounded-circle"
            id="menu-btn"
            onClick={() => {
              setIsSidebarOpen(!isSidebarOpen);
            }}
          >
            <AiOutlineMenu size={24} />
          </div>
          <div>
            <div className="mb-4 pt-2 pt-lg-4">
              <button
                href="/"
                type="submit"
                className="btn btn-primary text-light rounded-pill px-md-3 py-2"
              >
                <span className="small px-3 px-md-5 text-proportion">
                  Criar link de pagamento
                </span>
              </button>
            </div>
            <div className="row g-2 g-lg-5">
              <div className="col-md-6">
                <div className="mb-3">
                  <div className=" bg-secondary pannel-area-border p-3 mb-3">
                    <p className="text-primary smaller-font roboto">
                      SALDO DISPONÍVEL
                    </p>
                    <h1 className="text-info  fw-bold">R$ 0,00</h1>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <div className=" bg-secondary pannel-area-border p-3 mb-3">
                    <p className="text-primary smaller-font roboto">
                      SALDO A RECEBER
                    </p>
                    <h1 className="text-info  fw-bold">R$ 0,00</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 pt-2">
              {" "}
              <button
                
                type="submit"
                className="btn btn-primary text-light rounded-pill px-md-3 py-2"
              >
                <span className="small px-2 px-md-4 text-proportion">
                  Filtros
                </span>
              </button>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <div className=" bg-secondary pannel-area-border p-3 ">
                  <span className="smaller-font text-primary roboto">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent id rutrum metus. Nulla vel laoreet dui, sit amet
                    porttitor neque. Suspendisse porta augue leo, id vehicula
                    dolor malesuada quis. Integer ultrices placerat risus sit
                    amet sagittis. Nulla ex odio, elementum et tincidunt quis,
                    sodales quis est. Vestibulum sit amet dolor quis risus
                    pharetra tristique. Quisque accumsan nulla sit amet
                    hendrerit pulvinar. Interdum et malesuada fames ac ante
                    ipsum primis in faucibus. Quisque velit mauris, porttitor
                    nec suscipit at, pulvinar vel orci. Morbi fermentum lorem at
                    quam porttitor, eu bibendum ante elementum. Maecenas vitae
                    cursus sem. Vestibulum ante ipsum, elementum nec dapibus
                    sed, vehicula id velit. Nam nec erat lectus. Pellentesque
                    dapibus accumsan tortor quis fringilla. Nulla ornare felis
                    velit, at luctus justo pharetra eget. Ut finibus nisi sit
                    amet mauris interdum, nec maximus nisl posuere. Donec id
                    purus in turpis finibus ultricies. Sed convallis, augue
                    vitae convallis pellentesque, diam dui pretium erat, ac
                    consequat sem turpis fermentum urna. Ut at tincidunt ligula,
                    a luctus lectus. Donec dictum, tellus quis ornare
                    vestibulum, nisi orci venenatis ante, eu dictum diam libero
                    id tortor. Aliquam finibus convallis velit vitae vulputate.
                    Mauris at ligula felis. Donec vulputate vitae odio a
                    sodales. Vestibulum vitae arcu consequat, molestie sem eget,
                    sagittis turpis. Aliquam erat volutpat. Pellentesque varius
                    eget sem eu ullamcorper. Cras velit augue, gravida sit amet
                    pretium a, porttitor et augue. Aenean semper, magna vel
                    hendrerit iaculis, felis sem porta metus, sed gravida velit
                    arcu id metus. Pellentesque maximus blandit arcu vitae
                    scelerisque. Ut risus dolor, sollicitudin at aliquet ac,
                    elementum eu lectus. Quisque sit amet aliquam metus. Nulla
                    non eros eu turpis aliquet lacinia eget ut purus. Cras quis
                    est tortor. Vestibulum commodo tincidunt nunc, at molestie
                    lorem euismod eu. Integer varius hendrerit dolor eget
                    tempus. Curabitur in arcu sit amet diam pretium fermentum.
                    Nunc cursus finibus maximus. Vivamus vehicula facilisis
                    lorem quis gravida. Nullam fermentum justo eget massa
                    consequat, nec euismod enim ullamcorper. Etiam tempor,
                    tellus a tempus varius, risus tortor egestas nisi, quis
                    condimentum est mauris in elit. Curabitur auctor non nulla
                    non pulvinar. Donec sit amet laoreet massa. Orci varius
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus. Vivamus vitae commodo velit, eget bibendum
                    magna. Nullam nec tincidunt nisi. Pellentesque dictum
                    eleifend mi. Sed in ante dapibus, accumsan enim quis,
                    pretium enim. Ut sollicitudin arcu in turpis facilisis
                    commodo. Suspendisse potenti. Suspendisse potenti. Vivamus
                    id lorem ut dolor tempor tempus nec fringilla justo. Ut
                    elementum accumsan euismod. Nullam fermentum ipsum nibh,
                    eget tincidunt dolor sodales sed.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
