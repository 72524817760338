import React from "react";
import logoImg from "../img/logo_emepag.svg";
import facebookIcon from "../img/facebook-icon.png";
import instagramIcon from "../img/instagram-icon.png";
import linkedinIcon from "../img/linkedin-icon.png";

export default function Footer() {
    return (
        <footer className="p-2 p-lg-5 mt-5">
        <div className="container">
          <div className="row">
            <img src={logoImg} className="footer-logo img-fluid mb-5" alt="" />
          </div>
          <div className="row">
            <div className="col-md-3 d-flex flex-column mb-5">
              <span className="roboto fw-bold smaller-font">
                Emepag Soluções LTDA
              </span>
              <span className="roboto smaller-font">
                CNPJ: 17.288.629/0001-81
              </span>
              <span className="roboto smaller-font">
                Av. Projetada, S/N, Nova Caruaru 55014-908 - Caruaru - PE
              </span>
            </div>
            <div className="col-md-3 d-flex flex-column mb-5">
              <span className="roboto fw-bold smaller-font">Institucional</span>
              <span className="roboto smaller-font">
              <a
        
        href="#"
        className="text-black nounderline"
        data-bs-toggle="modal"
        data-bs-target="#modalPrivacy"
      >
        Política de Privacidade
      </a> 
              </span>
              <span className="roboto smaller-font"> <a
        
        href="#"
        className="text-black nounderline"
        data-bs-toggle="modal"
        data-bs-target="#modalTerms"
      >
        Termos de Uso
      </a> </span>
      <span className="roboto smaller-font"> <a
        
        href="#"
        className="text-black nounderline"
        data-bs-toggle="modal"
        data-bs-target="#modalCookies"
      >
        Política de Cookies
      </a> </span>
            </div>
            <div className="col-md-3 d-flex flex-column mb-5">
              <span className="roboto fw-bold smaller-font">
                Central de suporte
              </span>
              <span className="roboto smaller-font">
                WhatsApp (81) 98280-0888
              </span>
              <span className="roboto smaller-font">
                E-mail: suporte@emepag.com.br
              </span>
              <span className="roboto smaller-font">
                Segunda à sexta-feira, das 09h00 às 18h00 Sábado, das 09h00 às
                13h00
              </span>
            </div>
            <div className="col-md-3 d-flex flex-column mb-5">
              <span className="roboto fw-bold smaller-font mb-3">
                Redes Sociais
              </span>
              <div className="d-flex flex-row">
                <a
                  href="https://www.facebook.com/emepag"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="social-icon"
                >
                  <img src={facebookIcon} className="me-2" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/eme.pag/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="social-icon"
                >
                  <img src={instagramIcon} className="me-2" alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/emepag/about/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="social-icon"
                >
                  <img src={linkedinIcon} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
}
