import React from "react"

export default function Modals() {
    return (
        <div>
            <div
        className="modal fade"
        id="modalTerms"
        tabindex="-1"
        
      >
        <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-primary" id="ModalLabel">
                Termos de Uso
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
            <div>
  <p><strong>TERMOS E CONDIÇÕES DE USO DO SISTEMA DE PAGAMENTO “EMEPAG” PELO USUÁRIO-COMPRADOR</strong></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Considerações gerais:</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>(i)</span> <span style={{fontWeight: 400}}>O sistema </span><strong>EMEPAG</strong><span style={{fontWeight: 400}}> é uma solução tecnológica que consiste em programa de computador, disponível também em smartphone,&nbsp; capaz de gerir e intermediar pagamentos, bem como de gerir os riscos das transações efetuadas;</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>(ii)</span> <span style={{fontWeight: 400}}>O </span><strong>USUÁRIO-COMPRADOR</strong><span style={{fontWeight: 400}}> adquiriu mercadorias e/ou serviços em loja(s) virtual(is) que utiliza(m) tal sistema como meio de pagamento.</span></p>
  <p><br /><br /></p>
  <p><strong>O USUÁRIO-COMPRADOR declara-se CIENTE E DE ACORDO que:</strong></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>O EMEPAG </strong><span style={{fontWeight: 400}}>é apenas uma empresa facilitadora/meio de pagamento e que não atua ou é regulada como instituição financeira ou demais a serem autorizadas pelo Banco Central do Brasil – “BACEN”.</span></strong></li>
  </ul>
  <p>&nbsp;</p>
  <ol>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Em razão da qualidade de empresa facilitadora/meio de pagamento do </span><strong>EMEPAG</strong><span style={{fontWeight: 400}}> todas as cobranças serão emitidas pelo </span><strong>EMEPAG</strong><span style={{fontWeight: 400}}>, que constará como beneficiário das mesmas.&nbsp;</span></li>
    <li style={{fontWeight: 400}}><strong>O EMEPAG NÃO POSSUI QUALQUER RELAÇÃO SOCIETÁRIA COM O USUÁRIO-VENDEDOR</strong><span style={{fontWeight: 400}}> que o </span><strong>USUÁRIO-COMPRADOR</strong><span style={{fontWeight: 400}}> utilizou ou está em negociação para adquirir PRODUTOS E/OU SERVIÇOS, atuando </span><strong>SIMPLESMENTE COMO MEIO DE PAGAMENTO</strong><span style={{fontWeight: 400}}>.</span></li>
    <li style={{fontWeight: 400}}><strong>O EMEPAG</strong><span style={{fontWeight: 400}}> não tem qualquer responsabilidade pela não-entrega dos Produtos/Serviços adquiridos pelo </span><strong>USUÁRIO-COMPRADOR</strong><span style={{fontWeight: 400}}> e/ou pelo atraso na entrega e/ou por qualquer vício que haja no produto/serviço, sendo que qualquer insatisfação do </span><strong>USUÁRIO-COMPRADOR</strong><span style={{fontWeight: 400}}>, deverá ser reclamada diretamente do </span><strong>USUÁRIO-VENDEDOR</strong><span style={{fontWeight: 400}}>.</span></li>
  </ol>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Os presentes Termos e Condições de Uso do Usuário-Comprador são complementares aos Termos e Condições de Uso do Usuário-Vendedor, que você encontra descritos abaixo.</span></p>
  <p>&nbsp;</p>
  <p><strong>POLÍTICA PARA USO DO SISTEMA DE PAGAMENTO “EMEPAG” PELO USUÁRIO-VENDEDOR</strong></p>
  <p><br /><br /></p>
  <p><span style={{fontWeight: 400}}>Considerações gerais:</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>(i)</span> <span style={{fontWeight: 400}}>O sistema </span><strong>EMEPAG</strong><span style={{fontWeight: 400}}> é uma solução tecnológica que consiste em programa de computador, disponível também em smartphone,&nbsp; capaz de gerir e intermediar pagamentos, bem como de gerir os riscos das transações efetuadas;</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>(ii)</span> <span style={{fontWeight: 400}}>O </span><strong>USUÁRIO-VENDEDOR</strong><span style={{fontWeight: 400}}> fornece mercadorias e/ou serviços em loja(s) virtual(is) que utiliza(m) tal sistema como meio de pagamento.</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Ao clicar em "Li e Aceito", o Usuário reconhece que, de livre e espontânea vontade, adere, aceita e concorda com os presentes Termos e Condições de uso e demais documentos por ele mencionados. A utilização de quaisquer dos serviços oferecidos implica na integral e irrestrita compreensão, aceitação e vinculação do Usuário aos Termos.</span></p>
  <p>&nbsp;</p>
  <p><strong>Dos Termos e Condições de Uso</strong></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>O EMEPAG </strong><span style={{fontWeight: 400}}>se trata de uma empresa facilitadora/meio de pagamento e que não atua ou é regulada como instituição financeira ou demais a serem autorizadas pelo Banco Central do Brasil – “BACEN”.</span></strong></li>
  </ul>
  <p>&nbsp;</p>
  <ol>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Em razão da qualidade de empresa facilitadora/meio de pagamento do </span><strong>EMEPAG</strong><span style={{fontWeight: 400}}> todas as cobranças serão emitidas pelo </span><strong>EMEPAG</strong><span style={{fontWeight: 400}}>, que constará como beneficiário das mesmas.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Na utilização dos serviços, o Usuário deverá observar, respeitar e cumprir as disposições destes Termos e Condições de Uso e da legislação brasileira, sobretudo as disposições da Lei 9.613/1998 (Lei da Lavagem de Dinheiro), artigo 171 (Estelionato) e seguintes do Código Penal e da legislação que for aplicável ao próprio negócio.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Estes Termos e Condições de Uso poderão sofrer alterações, periódicas ou não, seja por questões legais, regulatórias ou estratégias do EMEPAG.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>É de única e inteira responsabilidade do Usuário a verificação periódica destes Termos. Contudo, o EMEPAG poderá comunicar o Usuário sobre alterações significativas através de avisos na página principal do site e/ou por e-mail, indicando quando essas modificações entrarão em vigor.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O aceite, ainda que tácito, das alterações pelo Usuário se dá pela manutenção de utilização da plataforma e os Termos permanecem vinculando as partes. Em caso de discordância, o Usuário poderá desativar seu cadastro a qualquer momento, desde que não haja dívidas em aberto.</span></li>
  </ol>
  <p>&nbsp;</p>
  <p><strong>Do Cadastro</strong></p>
  <p>&nbsp;</p>
  <ol>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Para contratar serviços do EMEPAG é necessária a realização de um cadastro. O Usuário poderá apenas utilizar serviços em nome da empresa da qual seja Representante Legal.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O cadastro de Pessoa Jurídica somente pode ser realizado por seu representante legal.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O cadastro é de uso único e intransferível, e o Usuário se compromete a não informar dados a terceiros, responsabilizando-se integralmente pelo uso da conta.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Ao se cadastrar, o Usuário criará sua senha, que será utilizada para acessar sua conta. A senha é personalíssima, intransferível e de exclusiva responsabilidade do Usuário.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O Usuário deverá informar imediatamente ao EMEPAG caso verifique utilização ou conhecimento destas informações por terceiros, sob pena de presunção de legitimidade das operações realizadas através de seus dados cadastrais.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Para que haja a efetivação do cadastro, é necessário que o Usuário forneça seus documentos pessoais e/ou os documentos sociais da pessoa jurídica que representa, além de vincular todas as informações solicitadas no processo de adesão e previstas pelas normas editadas pelo Banco Central do Brasil.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O EMEPAG pode solicitar ajustes nas informações cadastrais sempre que necessário, inclusive com a requisição de novos documentos ou atualização de documentos já existentes no cadastro.</span></li>
  </ol>
  <p>&nbsp;</p>
  <p><strong>Da Política de Uso pelo Usuário-Vendedor</strong></p>
  <p>&nbsp;</p>
  <ol>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O Usuário é o único responsável pelos produtos e serviços que comercializa, devendo este cumprir todas as regras e condições acordadas com seu cliente e se limitando a vender produtos e/ou serviços dos quais possa garantir a entrega e/ou a integral prestação.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O Usuário deverá cumprir todas as condições legais e regulamentares aplicáveis à sua atividade, incluindo as normas definidas pelo Código de Defesa do Consumidor (Lei Federal 8.078/1990).</span></li>
  </ol>
  <p>&nbsp;</p>
  <p><strong>Das Emissões de Cobrança</strong></p>
  <p>&nbsp;</p>
  <ol>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O EMEPAG disponibiliza um método de emissão de cobrança, disponível na aba "Gerar Link de Pagamento" . Os valores de cobranças pagas são recebidos pelo EMEPAG, que, após o prazo e o desconto das taxas aplicáveis, disponíveis na aba "Taxas", disponibiliza o valor líquido na conta do Usuário.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O EMEPAG não tem qualquer controle das informações de pagamento inseridas manualmente durante a emissão e pagamento de cobranças. O Usuário é integralmente responsável pelo preenchimento correto dos campos necessários.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>As taxas cobradas e tempo de compensação para cobranças pagas pelos diferentes métodos disponíveis são distintos e podem ser verificados nas abas de "Formas de Pagamento" e de "Taxas". É de responsabilidade do Usuário, no momento de emissão de uma cobrança, determinar quais opções de pagamento ele oferecerá ao seu cliente, ou Usuário-Comprador. Ao selecionar as opções de pagamento disponíveis para a emissão de cobranças, o Usuário aceita expressamente todas as taxas aplicáveis à(s) opção(ões) selecionada(s).</span></li>
  </ol>
  <p>&nbsp;</p>
  <p><strong>Das Taxas dos Serviços</strong></p>
  <p>&nbsp;</p>
  <ol>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>As taxas cobradas pelo EMEPAG estão previstas na aba "Taxas" e podem ser alteradas a qualquer momento sem aviso prévio. É de responsabilidade do Usuário acompanhar e verificar as taxas aplicáveis no momento de cada transação. Em caso de dúvidas, o Usuário poderá consultar a aba "Taxas" e/ou acionar o atendimento da equipe do EMEPAG para obter orientação sobre as taxas aplicáveis.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Poderão ser oferecidos serviços adicionais pelo EMEPAG, que serão cobrados mediante contrato de prestação de serviços personalizados.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>As taxas serão cobradas de maneira cumulativa, conforme o número de operações realizadas.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>No documento de contratação do serviço de modalidade personalizada serão especificadas, dentre outras, as condições de pagamento, valores e prazos conforme a vontade das partes contratantes.</span></li>
  </ol>
  <p>&nbsp;</p>
  <p><strong>Das Responsabilidades</strong></p>
  <p>&nbsp;</p>
  <ol>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O EMEPAG fornece a estrutura necessária para que o Usuário administre os seus pagamentos, agindo como intermediadora de pagamento, e não participa, de forma alguma, da respectiva prestação de serviços ou venda de produtos.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O EMEPAG se responsabiliza pela execução da prestação de serviços ou vendas e não se responsabiliza por vícios de qualidade dos serviços ou produtos comercializados pelo Usuário.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O EMEPAG não garante a qualidade e/ou a entrega dos produtos e serviços envolvidos nas cobranças emitidas por meio de sua plataforma de intermediação de pagamentos.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Em caso de ações judiciais por descumprimento de qualquer das regras estabelecidas nestes Termos e Condições de Uso, ou de qualquer das leis brasileiras por parte do Usuário, este assume total e exclusiva responsabilidade, comprometendo-se a isentar a O EMEPAG de qualquer responsabilidade solidária e/ou subsidiária.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O Usuário se compromete a não burlar, desativar ou, de qualquer forma, interferir em recursos e/ou ferramentas relacionados à segurança do site do EMEPAG, sob pena de incorrer nas medidas judiciais cabíveis.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>O Usuário se compromete a defender, indenizar e isentar de qualquer responsabilidade o EMEPAG, seus executivos, subsidiárias, afiliados, sucessores, prepostos, administradores, agentes, prestadores de serviços, fornecedores e/ou funcionários com relação a toda e qualquer reclamação, perda, dano, obrigação, custos, dívidas ou despesas (incluindo, dentre outros, honorários advocatícios e custas processuais) incorridas em razão de: (i) mau uso das cobranças emitidas; (ii) violação e/ou não observância de quaisquer dispositivos destes Termos e Condições de Uso; (iii) violação de qualquer direito de terceiros. As obrigações de defesa e indenização permanecerão em vigência mesmo após a cessação do uso e/ou acesso do site.</span></li>
  </ol>
  <p>&nbsp;</p>
  <p><strong>Dos Canais de Atendimento</strong></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Ficam à disposição do Usuário os canais de atendimento do EMEPAG, via e-mail, telefone e em formulário de atendimento encontrado na aba "Contato".</span></p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>E-mail: </span><a href="mailto:contato@emepag.com.br"><span style={{fontWeight: 400}}>contato@emepag.com.br</span></a></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Telefone: (81) 9 9699-8508</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Fale Conosco: </span><a href="http://www.emepag.com.br/contato"><span style={{fontWeight: 400}}>www.emepag.com.br/contato</span></a></li>
  </ul>
  <p><span style={{fontWeight: 400}}>Ao utilizar o EMEPAG você confirma que está ciente e de acordo com os seus Termos Condições de Uso, que configura Contrato entre as partes.</span></p>
  <p><span style={{fontWeight: 400}}>Em caso de dúvidas sobre as disposições desse documento ou demais necessidade de maiores informações, sinta-se livre para entrar em contato conosco.</span></p>
  <p><br /><br /><br /></p>
</div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary rounded-pill"
                data-bs-dismiss="modal"
              >
              Entendi
              </button>
              
            </div>
          </div>
        </div>
      </div>

      {/* <!--Modal Privacy--> */}

      

      <div
        className="modal fade"
        id="modalPrivacy"
        tabindex="-1"
        
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-primary" id="ModalLabel">
                Política de Privacidade
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-start">
            <div>
  <p><strong>POLÍTICA DE PRIVACIDADE DO EMEPAG</strong></p>
  <p><span style={{fontWeight: 400}}>&nbsp;Atualizada em 25 de outubro de 2021&nbsp;</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>O Emepag (“nós”) leva a privacidade a sério. Por isso, nesta Política de Privacidade (“Política”) explicamos as formas como nós utilizamos, armazenamos, compartilhamos e protegemos os dados pessoais coletados a partir do uso dos nossos serviços e soluções (coletivamente “Serviços”) e da visita aos nossos websites e como os titulares dos dados podem exercer os direitos previstos na legislação aplicável.&nbsp;</span></p>
  <p><span style={{fontWeight: 400}}>Os dados pessoais que coletamos dependem do contexto das suas interações com o Emepag. Para facilitar a sua compreensão, explicamos nesta Política como tratamos os dados de (i) clientes do Emepag (“Clientes”), consumidores de Clientes (“Consumidores”) e de usuários dos nossos websites (“Usuários”), coletivamente (“Titulares” ou “você”).&nbsp;</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Quem somos nós</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>O Emepag oferece serviços de facilitação de pagamentos. Oferecemos aos nossos clientes a possibilidade de gerar links de pagamento, com rapidez e segurança, e agimos como intermediadores de pagamento e garantidores da segurança das transações, entre outras funções. Saiba mais sobre os nossos serviços acessando o site: </span><a href="http://www.emepag.com.br"><span style={{fontWeight: 400}}>www.emepag.com.br</span></a><span style={{fontWeight: 400}}>.</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Definições importantes</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><strong>Lei Geral de Proteção de Dados (LGPD).</strong><span style={{fontWeight: 400}}> Lei Federal nº 13.709 publicada no dia 14 de agosto de 2018 que regula as atividades de Tratamento de Dados Pessoais, inclusive nos meios digitais, por pessoa natural ou por pessoa jurídica de direito público ou privado, com o objetivo de proteger os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural.</span></p>
  <p><strong>Agentes de Tratamento.</strong><span style={{fontWeight: 400}}> são aqueles responsáveis pelo Tratamento de Dados Pessoais e estão separados em duas categorias: o Controlador e o Operador. O </span><strong>Controlador</strong><span style={{fontWeight: 400}}> é a pessoa ou a empresa responsável pelas decisões referentes ao Tratamento de Dados Pessoais. O </span><strong>Operador</strong><span style={{fontWeight: 400}}>, por sua vez, é a pessoa ou a empresa que realiza o Tratamento de Dados Pessoais em nome do Controlador, seguindo as suas instruções.&nbsp;</span></p>
  <p><strong>Dados Pessoais.</strong><span style={{fontWeight: 400}}> informações relacionadas à pessoa natural identificada ou identificável Tratamento. qualquer operação realizada com os Dados Pessoais de forma automatizada ou não, ou seja, é a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, armazenamento, arquivamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Clientes</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><strong>3.1 Dados que coletamos</strong></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>De forma geral, quando um Cliente (ou para os fins desta seção, “você”) ou a sua empresa contrata, utiliza ou interage com os Serviços, nós coletamos os seus dados pessoais, que podem ser divididos em três grandes categorias:</span></p>
  <p>&nbsp;</p>
  <p><strong>(i) </strong> <strong>Dados que você nos fornece:</strong><span style={{fontWeight: 400}}> quando você contrata, utiliza ou interage com os Serviços ou se comunica conosco de alguma forma, você (ou a sua empresa) pode nos fornecer alguns dados. Esses dados incluem:</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Dados de contato profissional, como o seu nome, cargo, empresa, localização, número de telefone e endereço de e-mail;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Credenciais de conta, como o seu endereço de e-mail, usuário e senha;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Credenciais de conta, como o seu endereço de e-mail, usuário e senha;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Dados relacionados a suporte técnico e solução de problemas. Esses dados incluem dados de abertura de chamado, como o tipo de produto ou serviço relacionado ao seu pedido de ajuda, dados de contato ou autenticação e o conteúdo das suas comunicações conosco, incluindo gravações telefônicas e histórico de mensagens.</span></li>
  </ul>
  <p>&nbsp;</p>
  <p><strong>(ii)</strong> <strong>Dados que coletamos automaticamente: </strong><span style={{fontWeight: 400}}>quando você utiliza os nossos serviços, nós podemos coletar e armazenar dados de forma automática. Esses dados incluem:</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Dados de dispositivos que você usa para acessar os Serviços, como o seu endereço de IP, sistema operacional, informação de geolocalização e identificador do dispositivo;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Dados de registros, como o endereço de IP e suas atividades nos Serviços (como por exemplo a data e hora associadas com o seu uso, as páginas e arquivos visualizados, pesquisas e outras ações que você realiza (como, por exemplo, quais funcionalidades você utilizou).&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Dados de navegação, ou seja, dados sobre como você utiliza e interage com os nossos Serviços, o que pode incluir das datas em que você utilizou os Serviços e as suas atividades de navegação. Nós também coletamos informações sobre a performance dos Serviços, incluindo métricas relacionadas ao envio de emails e outro tipo de comunicação que você envia por meio dos Serviços.&nbsp;</span></li>
  </ul>
  <p>&nbsp;</p>
  <p><strong>(iii)</strong> <strong>Dados que coletamos de outras fontes: </strong><span style={{fontWeight: 400}}>nós podemos coletar dados de outras fontes, como por exemplo bases de dados públicas, dados de perfis públicos em redes sociais e entidades de proteção ao crédito.</span></p>
  <p>&nbsp;</p>
  <p><strong>3.2 Como utilizamos esses dados</strong></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Nós utilizamos os dados coletados ou recebidos a partir da utilização dos Serviços para as seguintes finalidades:</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cumprir com nossas obrigações contratuais com você ou com a sua empresa. Isso inclui criar e gerenciar as contas que você possa ter conosco, verificar sua identidade, cobrar os valores devidos por você, fornecer, personalizar e aprimorar nossos serviços;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Notificá-lo sobre eventuais alterações em nossos produtos e serviços;&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Reforçar nossos procedimentos de segurança e proteção, visando a prestação de um serviço mais seguro e eficaz;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Realizar operações internas, incluindo suporte, solução de problemas, análise de dados, testes, pesquisas e estatística;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Fornecer informações sobre outros serviços e/ou produtos que oferecemos, similares aos já contratados por você;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Avaliar ou entender a eficácia da publicidade que veiculamos, visando fornecer publicidades relevantes para você;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Gerenciar riscos e detectar, prevenir e/ou remediar fraudes ou outras atividades potencialmente ilegais ou proibidas, além de violações de políticas, contratos ou termos de uso aplicáveis;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Conforme necessário, para estabelecer, exercer e defender direitos em processos judiciais, administrativos ou arbitrais;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cumprir com obrigações legais ou regulatórias, ou conforme exigido em um processo judicial, por qualquer órgão de aplicação da lei ou do governo com competência, ou que alegue ter competência, sobre o Emepag.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Para outras finalidades as quais fornecemos um aviso específico no momento da coleta, ou, de outro modo, conforme autorizado ou exigido por lei.</span></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Consumidores</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Essa seção se aplica aos dados pessoais de Consumidores de nossos Clientes (ou para os fins desta seção, “você”), quando estivermos atuando como Controladores. Note que em grande parte dos produtos e serviços que oferecemos, o Emepag atua como Operador dos dados pessoais, segundo as instruções de nossos Clientes. O Emepag não é responsável pelas práticas de privacidade ou segurança dos nossos Clientes. Para entender como seus dados são tratados por nossos Clientes, é importante consultar as respectivas políticas de privacidade.&nbsp;</span></p>
  <p>&nbsp;</p>
  <p><strong>4.1 Dados que coletamos</strong></p>
  <p>&nbsp;</p>
  <p><strong>(i)</strong> <strong>Dados que recebemos dos nossos Clientes: </strong><span style={{fontWeight: 400}}>Os Cliente do Emepag podem nos fornecer os seus dados pessoais por meio dos Serviços. Quando, por exemplo, você realiza uma compra na loja virtual do Cliente, o Cliente pode nos fornecer determinadas informações como o seu nome, dados de contato, como e-mail e telefone, ou dados de pagamento.&nbsp;</span></p>
  <p>&nbsp;</p>
  <p><strong>(ii)</strong> <strong>Dados que coletamos automaticamente: </strong><span style={{fontWeight: 400}}>quando você interage com os Serviços adquiridos pelo Cliente, como por exemplo, quando você interage com uma comunicação de marketing, navega pelo website do Cliente ou realiza uma compra na loja virtual do Cliente, nós podemos coletar informações sobre o seu dispositivo e as suas atividades. Essas informações incluem:</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Dados de dispositivos que você usa para acessar os Serviços, como o seu endereço de IP, sistema operacional, informação de geolocalização e identificadores únicos do dispositivo;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Dados de navegação, ou seja, dados sobre como você utiliza e interage com os nossos Serviços, o que pode incluir das datas em que você utilizou os Serviços e as suas atividades de navegação, como por exemplo as páginas que você visualizou. Nós também coletamos informações sobre a performance dos Serviços, incluindo métricas relacionadas ao envio de e-mails ou outras formas de comunicação enviados pelos nossos Clientes por meio dos Serviços.&nbsp;</span></li>
  </ul>
  <p>&nbsp;</p>
  <p><strong>(iii)</strong> <strong>Dados que coletamos de outras fontes:</strong><span style={{fontWeight: 400}}> nós podemos coletar dados de outras fontes, como por exemplo bases de dados públicas, dados de perfis públicos em redes sociais, entidades de proteção ao crédito, sobretudo quando você realiza uma compra em um website que utiliza os nossos serviços de intermediação de pagamento.</span></p>
  <p>&nbsp;</p>
  <p><strong>4.2 Como utilizamos esses dados</strong></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Nós podemos utilizar os dados coletados ou recebidos a partir da utilização dos Serviços pelos nossos Clientes para as seguintes finalidades:</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Fornecer, personalizar e aprimorar nossos Serviços;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Realizar operações internas, incluindo suporte aos nossos clientes, solução de problemas, análise de dados, testes, pesquisas e estatística;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Desenvolver estratégias de marketing para nossos Clientes, para que estes possam oferecer publicidades relevantes para você.</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Prosseguir com pedidos de pagamento efetuados por você, nas operações de intermediação de pagamento.&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Gerenciar riscos e detectar, prevenir e/ou remediar fraudes ou outras atividades potencialmente ilegais ou proibidas, além de violações de políticas ou termos de uso aplicáveis;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Estabelecer, exercer e defender direitos em processos judiciais, administrativos ou arbitrais;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cumprir com obrigações legais ou regulatórias, ou conforme exigido em um processo judicial, por qualquer órgão de aplicação da lei ou do governo com competência, ou que alegue ter competência, sobre o Emepag.</span></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Usuários</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Essa seção se aplica aos dados pessoais que coletamos quando os Usuários (ou para os fins dessa seção, “você”) visitam o website do Emepag.</span></p>
  <p>&nbsp;</p>
  <p><strong>5.1 Dados que coletamos</strong></p>
  <p>&nbsp;</p>
  <p><strong>(i) </strong> <strong>Dados que você nos fornece: </strong><span style={{fontWeight: 400}}>o nosso website dispõe de formas por meio das quais você pode entrar em contato conosco. Você pode nos fornecer dados pessoais, por exemplo, quando você manifesta interesse em obter mais informações sobre os nossos produtos e serviços, participa de pesquisas, se inscreve no nosso newsletter, faz o download de conteúdo informativo ou entra em contato conosco de alguma outra forma. Esses dados incluem:</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Dados de contato profissional, como o seu nome, cargo, empresa, localização, número de telefone e endereço de e-mail;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Área de atuação e interesses;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Natureza da sua comunicação conosco;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Quaisquer outras informações que você escolha nos fornecer.</span></li>
  </ul>
  <p>&nbsp;</p>
  <p><strong>(ii)</strong> <strong>Dados que coletamos automaticamente:</strong><span style={{fontWeight: 400}}> quando você utiliza os nossos websites, nós podemos coletar e armazenar dados de forma automática. Esses dados incluem:</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Dados de dispositivos que você usa para acessar os Serviços, como o seu endereço de IP, sistema operacional, informações de navegador, informação de geolocalização e identificadores únicos do dispositivo.&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Dados de navegação, ou seja, dados sobre como você utiliza e interage com os nossos Serviços, o que pode incluir das datas em que você utilizou os Serviços e as suas atividades de navegação, incluindo por exemplo a sua interação com os nossos materiais informativos. Nós também coletamos informações sobre a performance dos Serviços, incluindo métricas relacionadas ao envio de e-mails e outro tipo de comunicação que você envia por meio dos Serviços.&nbsp;</span></li>
  </ul>
  <p>&nbsp;</p>
  <p><strong>5.2 Como utilizamos esses dados</strong></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Nós podemos utilizar os dados coletados ou recebidos a partir da utilização do website para as seguintes finalidades:</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Fornecer, personalizar e aprimorar nosso website;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Reforçar nossos procedimentos de segurança e proteção, visando a prestação de um serviço mais seguro e eficaz;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Realizar operações internas, solução de problemas, análise de dados, testes, pesquisas e estatística;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Processar transações e gerenciar as suas contas online;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Enviar comunicações de marketing e recomendações de produtos ou serviços oferecidos pelo Emepag, de acordo com as suas preferências de marketing;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Avaliar ou entender a eficácia da publicidade que veiculamos, visando fornecer publicidades relevantes para você;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Gerenciar riscos e detectar, prevenir e/ou remediar fraudes ou outras atividades potencialmente ilegais ou proibidas, além de violações de políticas ou termos de uso aplicáveis;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Conforme necessário, para estabelecer, exercer e defender direitos em processos judiciais, administrativos ou arbitrais;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Cumprir com obrigações legais ou regulatórias, ou conforme exigido em um processo judicial, por qualquer órgão de aplicação da lei ou do governo com competência, ou que alegue ter competência, sobre o Emepag;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Para outras finalidades as quais fornecemos um aviso específico no momento da coleta, ou, de outro modo, conforme autorizado ou exigido por lei.</span></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Como compartilhamos dados pessoais</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Nós poderemos compartilhar os dados pessoais coletados com outras empresas do seu grupo econômico, com terceiros e parceiros de negócio, que sejam relevantes para fins de viabilizar a prestação dos Serviços. Referido compartilhamento ocorre com base nos seguintes critérios e para as finalidades descritas abaixo:</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><strong>Divisões internas do Emepag</strong><span style={{fontWeight: 400}}>: As divisões internas do Emepag podem compartilhar entre si os dados pessoais coletados a partir da prestação dos Serviços a fim de operar, executar, aprimorar, entender, personalizar, dar suporte, anunciar nossos Serviços e prevenir fraudes.</span></li>
  </ul>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><strong>Prestadores de serviço terceirizados:</strong><span style={{fontWeight: 400}}> Trabalhamos com prestadores de serviço terceirizados para nos ajudar a operar, executar, aprimorar, entender, personalizar, dar suporte e anunciar nossos Serviços. Quando compartilhamos dados com prestadores de serviço terceirizados, exigimos que eles utilizem seus dados de acordo com nossas instruções e termos ou mediante seu consentimento expresso, quando aplicável.</span></li>
  </ul>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><strong>Entidades de Proteção ao Crédito: </strong><span style={{fontWeight: 400}}>Podemos compartilhar os seus dados cadastrais com entidades dedicadas a reduzir o risco de crédito e proteger empresas e indivíduos contra fraudes, a fim de validar as informações fornecidas por você.&nbsp;</span></li>
  </ul>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><strong>Órgãos reguladores, autoridades judiciais ou administrativas:</strong><span style={{fontWeight: 400}}> podemos compartilhar as suas informações pessoais para prestar às autoridades competentes todas as informações que forem solicitadas com relação ao Titular e operações por ele executadas nos Sites. Ademais, podemos compartilhar os seus dados pessoais com autoridades públicas ou entidades privadas para combate à fraude e abuso no uso dos Serviços, para investigação de suspeitas de violação da lei, ou para combater qualquer outra suspeita de descumprimento das nossas políticas e contratos.</span></li>
  </ul>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><strong>Transferência de Ativos:</strong><span style={{fontWeight: 400}}> Caso uma empresa ou unidade de negócio do Emepag seja reorganizada ou vendida e sejam transferidos todos ou substancialmente todos os ativos a um novo proprietário, sua informação pessoal pode ser transferida ao comprador independentemente de sua autorização para garantir a continuidade dos serviços.</span></li>
  </ul>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><strong>Com a sua autorização:</strong><span style={{fontWeight: 400}}> Em outros casos não previstos acima, havendo o objetivo de compartilhamento dos dados pessoais e informações, enviaremos a você uma notificação com informações a respeito de tal compartilhamento para solicitar o seu consentimento, para finalidade determinada.</span></li>
  </ul>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><strong>Terceiros que questionem a forma de utilização ou conteúdo veiculado em nossos produtos e/ou serviços contratados:</strong><span style={{fontWeight: 400}}> A fim de possibilitar e cumprir com a livre iniciativa, a livre concorrência e a defesa de interesses dos usuários nas relações comerciais, nós podemos compartilhar seu nome, endereço, CNPJ ou CPF e endereço de e-mail em caso 10 de questionamento de consumidores e/ou cliente e/ou usuários relativo à forma e conteúdo veiculo em nossos produtos e/ou serviços.</span></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Cookies e Tecnologias de Rastreamento&nbsp;&nbsp;</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Nós e nossos parceiros podemos utilizar diversas tecnologias para coletar e armazenar, de forma automática, dados sobre o uso dos Serviços. Essas tecnologias incluem cookies, pixels, web beacons e SDKs. Nós usamos as informações coletadas por meio dessas tecnologias para otimizar e personalizar a sua experiência de navegação, direcionar campanhas publicitárias, desenvolver e aplicar funcionalidades de segurança e procedimentos de prevenção à fraude, dentre outras possíveis utilizações. Para mais informações, acesse a nossa Política de Cookies, disponível em nosso site no link: www.emepag.com.br/cookies</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Transferências de dados pessoais para fora do Brasil</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Nós poderemos transferir alguns de seus dados pessoais a prestadores de serviços localizados no exterior, incluindo prestadores de serviços em nuvem.</span></p>
  <p><span style={{fontWeight: 400}}>Quando seus dados pessoais forem transferidos para fora do Brasil, o Emepag adotará medidas apropriadas para garantir a proteção adequada de seus dados pessoais em conformidade com os requisitos da legislação aplicável de proteção de dados, incluindo por meio da celebração de contratos apropriados de transferência de dados com terceiros de seus dados pessoais quando exigidos.</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Interações Com Sites De Terceiros</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Podemos disponibilizar links para outros sites na Internet. O EMEPAG NÃO SE RESPONSABILIZA POR ESSES SITES E CONTEÚDOS E, AINDA, NÃO COMPARTILHA, SUBSCREVE, MONITORA, VALIDA OU ACEITA A FORMA COMO ESSES SITES OU FERRAMENTAS DE ARMAZENAMENTO DE CONTEÚDO COLETAM, PROCESSAM E TRANSFEREM SUAS INFORMAÇÕES PESSOAIS E PRIVADAS. Recomendamos que você consulte as respectivas políticas de privacidade de tais sites para se informar adequadamente a respeito do uso de suas informações pessoais por outros sites ou outras ferramentas.</span></p>
  <p><span style={{fontWeight: 400}}>Podemos, no entanto, registrar o seu acesso aos sites de terceiros, com o intuito de medir a relevância desses sites aos nossos usuários.</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><span style={{fontWeight: 400}}>&nbsp;</span><strong>Segurança</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Os Dados Pessoais tratados pelo Emepag são protegidos por medidas físicas, técnicas e organizacionais de segurança para reduzir riscos de perda, mau uso e acesso não autorizado, divulgação e alteração, tais como firewalls e criptografia de dados, controles de acesso físico a data centers, além de controles de autorização de acesso à informação. </span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>&nbsp;Direitos do Titular</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Os Titulares de dados pessoais têm direitos no que se refere aos seus dados pessoais e podem exercê-los entrando em contato conosco por meio do e-mail: </span><a href="mailto:contato@emepag.com.br"><span style={{fontWeight: 400}}>contato@emepag.com.br</span></a><span style={{fontWeight: 400}}>. Alguns exemplos incluem:</span></p>
  <p>&nbsp;</p>
  <ul>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Confirmação da existência de tratamento de dados pessoais;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Acesso aos dados pessoais, nos termos da legislação aplicável;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Correção de dados incompletos, inexatos ou desatualizados;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Portabilidade dos dados;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Exclusão de dados, quando esses forem tratados com base no consentimento do Titular ou quando os dados forem desnecessários, excessivos ou tratados em desconformidade com a legislação aplicável;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Solicitação de informações sobre uso compartilhado de dados. Note que essa Política de Privacidade descreve as atividades de compartilhamento de dados pessoais pelo Emepag. Para receber mais informações sobre como compartilhamos os seus dados entre em contato conosco;&nbsp;</span></li>
    <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Revogação do consentimento, quando aplicável</span></li>
  </ul>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Por motivos de segurança, somente poderemos atender à sua solicitação se tivermos certeza da sua identidade. Sendo assim, poderemos solicitar dados ou informações adicionais para a confirmação da identidade e da autenticidade do Titular.</span></p>
  <p>&nbsp;</p>
  <p><strong>Note que, como mencionado acima, em grande parte dos produtos e serviços que oferecemos, o Emepag atua como operador dos dados pessoais, segundo as instruções de nossos Clientes. Caso você seja um Consumidor e deseje exercer os seus direitos com relação aos dados pessoais que o Emepag trata enquanto operador, você deve contatar o Cliente que está utilizando os Serviços.&nbsp;</strong></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>&nbsp;Término do Tratamento</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Esta Política de Privacidade se aplica às circunstâncias acima mencionadas durante todo o período em que o Emepag armazenar os dados pessoais. Nós armazenamos e mantemos suas informações: (i) pelo tempo exigido por lei; (ii) até o término do tratamento de dados pessoais, conforme mencionado abaixo; ou (iii) pelo tempo necessário a preservar o legítimo interesse do Emepag. Assim, trataremos seus dados, por exemplo, durante os prazos prescricionais aplicáveis ou enquanto necessário para cumprimento de obrigação legal ou regulatória. O término do tratamento de dados pessoais ocorrerá nos seguintes casos: (i) quando a finalidade para qual o dado pessoal foi coletado for alcançada, e/ou os dados pessoais coletados deixarem de ser necessários ou pertinentes ao alcance de tal finalidade; (ii) quando o Titular solicitar a exclusão de seus dados; e (iii) quando houver uma determinação legal neste sentido.</span></p>
  <p><span style={{fontWeight: 400}}>Nos casos de término de tratamento de dados pessoais, ressalvadas as hipóteses estabelecidas pela legislação aplicável ou pela presente Política de Privacidade, os dados pessoais serão eliminados e esta Política não será mais aplicável ao seu relacionamento com o Emepag.&nbsp;</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>&nbsp;Alteração na Política de Privacidade</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Com a constante evolução do modelo de negócio, o Emepag reserva-se o direito de alterar esta política de privacidade a qualquer momento, mediante publicação da versão atualizada em seu website. Caso exista uma alteração material no tratamento dos dados pessoais, você será informado e terá a oportunidade de analisar a política revisada antes de decidir continuar usando nossos Serviços</span></p>
  <p><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></p>
</div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary rounded-pill"
                data-bs-dismiss="modal"
              >
              Entendi
              </button>
              
            </div>
          </div>
        </div>
      </div>

      {/* <!--Modal Cookies--> */}

      

      <div
        className="modal fade"
        id="modalCookies"
        tabindex="-1"
        
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-primary" id="ModalLabel">
                Política de Cookies
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
            <div>
  <p><strong>POLÍTICA DE COOKIES EMEPAG</strong></p>
  <p><span style={{fontWeight: 400}}>Atualizada em 25 de outubro de 2021</span></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Olá! Seja bem-vindo à Política de Cookies do Emepag (“nós”). O objetivo desta política é fornecer ao usuário do Site e dos serviços oferecidos pelo Emepag (“usuário” ou “você”), informações claras e acessíveis sobre os cookies que utilizamos em nosso website e no aplicativo para dispositivos móveis (coletivamente, “Sites”) e a forma com que isso afeta a sua experiência como usuário. A presente Política de Cookies é complementar à nossa Política de Privacidade, que você pode acessar clicando aqui: </span><span className="roboto smaller-font">
              <a
        
        href="#"
        className=" nounderline text-primary fw-bold"
        data-bs-toggle="modal"
        data-bs-target="#modalPrivacy"
      >
        Política de Privacidade
      </a> 
              </span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>O que são cookies?</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Cookies são ferramentas essenciais na navegação online. Consistem em arquivos que identificam a interação entre você, nossos Sites e nossas redes sociais ou anúncios, e que são transferidos para o seu equipamento (computador, tablet ou smartphone) visando otimizar e personalizar a sua experiência de navegação nos Sites. O termo “cookie” pode ser usado para descrever uma série de tecnologias, incluindo, mas não se limitando a pixel tags, web beacons e identificadores de dispositivo móvel.&nbsp;</span></p>
  <p><span style={{fontWeight: 400}}>O Emepag usa tanto cookies de sessão quanto cookies persistentes. Os cookies de sessão coletam informações que não permitem a identificação do usuário e são automaticamente deletados quando a janela do seu navegador for fechada. Já os cookies persistentes são armazenados no seu computador ou dispositivo móvel até sua expiração e coletam informações que podem identificar o indivíduo.&nbsp;</span></p>
  <p><span style={{fontWeight: 400}}>Além disso, os cookies utilizados nos Sites podem ser “proprietários”, isto é, cookies definidos por nós - ou por um terceiro em nosso nome - ou “de terceiros”, que permitem que recursos ou funcionalidades de terceiros confiáveis sejam fornecidos nos Sites ou por meio deles ou em serviços que você utiliza, como anúncios, conteúdo interativo ou analytics. Esses terceiros podem reconhecer o seu dispositivo quando você navega em nossos Sites ou utiliza nossos serviços ou quando você navega em outros sites ou utiliza outros serviços.&nbsp;</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Como os cookies são utilizados pelo Emepag</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Abaixo listamos os diferentes tipos de cookies que podem ser utilizados nos Sites do Emepag.</span></p>
  <p>&nbsp;</p>
  <p><strong>Cookies Essenciais</strong></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Os cookies essenciais são necessários para o funcionamento dos Sites e prestação dos serviços a você. Sem estes cookies, os Sites não funcionam tão bem como nós gostaríamos, e podemos não ser capazes de fornecer determinados serviços ou recursos a eles associados.</span></p>
  <p><br /><br /></p>
  <table>
    <tbody>
      <tr>
        <td>
          <p><strong>Tipo de cookie</strong></p>
        </td>
        <td>
          <p><strong>Descrição</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Sessão</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Os cookies de sessão são usados para manter o estado da aplicação.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Balanceamento de Carga</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Os cookies de balanceamento de carga são usados para distribuir e para diminuir a carga do servidor.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Identificação do usuário</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Os cookies de identificação de usuário são usados para garantir que os usuários só vejam a sua própria informação.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Segurança</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Os cookies de segurança são usados para os controles de segurança e verificações.</span></p>
        </td>
      </tr>
    </tbody>
  </table>
  <p>&nbsp;</p>
  <p><strong>Cookies de Preferências</strong></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Os cookies de preferências coletam informações sobre suas escolhas e preferências, permitindo que os nossos Sites se lembrem do idioma, localização, dispositivo utilizado ou outras configurações e que a sua experiência seja personalizada de acordo com essas preferências.</span></p>
  <p>&nbsp;</p>
  <table>
    <tbody>
      <tr>
        <td>
          <p><strong>Tipo de Cookie</strong></p>
        </td>
        <td>
          <p><strong>Descrição</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Idioma</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Os cookies de idioma são usados para armazenar a língua que o usuário selecionou, e para mostrar as opções corretas.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Localização</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>O endereço aproximado do usuário (cidade, estado, país, código postal), conforme determinado pelo endereço IP, é armazenado para permitir selecionar automaticamente o país, mostrando assim quais os estabelecimentos mais próximos do usuário.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Mobile</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Se o usuário visualiza o Site num dispositivo móvel, é definido um cookie para indicar que foi selecionado o site principal (ou seja, que o dispositivo suporta Flash), ou o site móvel não-Flash.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Site de Referência</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>O site de referência é registrado para melhor se entender a preferência do&nbsp;</span></p>
          <p><span style={{fontWeight: 400}}>usuário.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Última visita e atividade</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>As datas das últimas visitas e atividades e outras informações semelhantes são gravadas para que se possa fornecer aos usuários atualizações sobre "o que mudou no site desde sua última visita", e para melhor se compreender as suas preferências.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Vídeos recentemente visualizados</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>As datas e títulos de vídeos recentemente visualizados são registados para melhor se definir as preferências do usuário.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Flash cookies</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Os flash cookies são usados de forma a permitir a reprodução de conteúdo de áudio e vídeo.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Histórico de Página</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Os cookies de histórico da página são usados para controlar a sequência de páginas que o usuário visita. Se o usuário receber uma mensagem de erro ao visitar o Site, as informações do cookie são guardadas num ficheiro de registo para o relatório de erros e resolução.</span></p>
        </td>
      </tr>
    </tbody>
  </table>
  <p>&nbsp;</p>
  <p><strong>Cookies Analíticos</strong></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Os cookies analíticos coletam informações sobre a utilização dos Sites, permitindo-nos aperfeiçoar o seu funcionamento. Os cookies analíticos, por exemplo, mostram-nos quais são as páginas mais visitadas nos Sites, ajudam-nos a registar quaisquer dificuldades que os usuários sintam na navegação nos Sites, e mostram-nos se a nossa publicidade é eficaz ou não. Isso nos possibilita ver os padrões globais de uso dos Sites de forma anônima e agregada.&nbsp;</span></p>
  <p><br /><br /></p>
  <table>
    <tbody>
      <tr>
        <td>
          <p><strong>Tipo de Cookie</strong></p>
        </td>
        <td>
          <p><strong>Descrição</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Google Analytics</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Os cookies do Google Analytics recolhem dados estatísticos para melhorar a apresentação e navegação no Site. A Google completa os dados agregados com dados demográficos e outra informação de interesse, para que possamos entender melhor nossos visitantes.</span></p>
        </td>
      </tr>
    </tbody>
  </table>
  <p>&nbsp;</p>
  <p><strong>Cookies de Publicidade</strong></p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Os cookies de publicidade são utilizados para fins de marketing, permitindo o direcionamento dos anúncios que aparecem para você com base nos seus interesses. O objetivo é tornar o conteúdo publicitário mais relevante para você, melhorar os relatórios sobre o desempenho da campanha e evitar a exibição de anúncios que você já viu.&nbsp;</span></p>
  <p><span style={{fontWeight: 400}}>Algumas das páginas que você visita nos Sites também podem coletar informações através do uso de etiquetas de pixels (ou pixel tags), que podem ser compartilhadas com terceiros que apoiam diretamente as nossas atividades de promoção e desenvolvimento do website. Por exemplo, as informações de utilização dos nossos Sites, podem ser compartilhadas com um terceiro, como uma agência de publicidade, para que possamos melhor direcionar anúncios nos banners em nossos websites.</span></p>
  <p><br /><br /></p>
  <table>
    <tbody>
      <tr>
        <td>
          <p><strong>Tipo de Cookie</strong></p>
        </td>
        <td>
          <p><strong>Descrição</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Publicidade</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Distribuir publicidade direcionada/ comportamental</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Estudos de Mercado</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Realizar estudos de mercado.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Campanha / Promoção</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Medir a eficácia da campanha.</span></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><span style={{fontWeight: 400}}>Detecção de Fraude</span></p>
        </td>
        <td>
          <p><span style={{fontWeight: 400}}>Detectar a fraude do clique.</span></p>
        </td>
      </tr>
    </tbody>
  </table>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Plugins de mídia social</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Também usamos botões e/ou plugins de mídia social nos Sites. Eles permitem que você se conecte com sua rede social de várias maneiras. Para que estes funcionem, os sites de mídia social, como, por exemplo, o Facebook, definirão as tecnologias de rastreamento em nosso site que podem ser usados para aprimorar seu perfil em seus sites ou contribuir com os dados armazenados para vários fins delineados em sua respectiva política de privacidade.</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Como gerir cookies?</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Embora a maioria dos navegadores de Internet esteja inicialmente configurada para aceitar cookies automaticamente, você poder alterar as configurações para bloquear esses cookies ou para alertá-lo quando os cookies são enviados para o dispositivo. Para saber melhor consulte as opções de ajuda de seu navegador.</span></p>
  <p><span style={{fontWeight: 400}}>Note que se os cookies forem desativados ou removidos, nem todos os recursos dos Sites poderão funcionar como pretendido. Por exemplo, você pode não ser capaz de visitar certas áreas dos nossos Sites ou pode não receber informações personalizadas quando visitar os Sites.&nbsp;</span></p>
  <p><span style={{fontWeight: 400}}>Caso você use diferentes dispositivos para visualizar e acessar os Sites (por exemplo, computador, smartphone, tablet, etc.), deve configurar cada navegador de cada dispositivo para atender às suas preferências de cookies.</span></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <ul>
    <li><strong><strong>Contate-nos</strong></strong></li>
  </ul>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><span style={{fontWeight: 400}}>Caso tenha alguma pergunta acerca desta Política de Cookies, por favor contate-nos por meio do email: contato@emepag.com.br</span></p>
</div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary rounded-pill"
                data-bs-dismiss="modal"
              >
              Entendi
              </button>
              
            </div>
          </div>
        </div>
      </div>
            
        </div>
    )
}
