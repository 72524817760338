import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import SignUp from "./pages/signup";
import ThankYou from "./pages/postsignup";
import SignIn from "./pages/signin";
import Pannel from "./pages/pannel";

import Contact from "./pages/contact";
import { CookiesProvider } from "react-cookie";

export default function App() {
  return (
    <CookiesProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cadastro" element={<SignUp />} />
        <Route path="/obrigado" element={<ThankYou />} />
        <Route path="/entrar" element={<SignIn />} />
        <Route path="/painel" element={<Pannel />} />

        <Route path="/contato" element={<Contact />} />
      </Routes>
    </CookiesProvider>
  );
}
