import React from "react";
import Lottie from "react-lottie";

import logoImg from "../img/logo_emepag.svg";
import signinAnimation from "../animations/key.json";
import CookieAlert from "../components/CookieAlert";
import Modals from "../components/Modals";

const lottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function signin() {
  return (
    <div className="hide-overflow">
      <Modals/>
      {/*<!--Navbar logo-->*/}

      <nav className="navbar navbar-light mb-2 mb-lg-5">
        <div className="container px-2 px-lg-5">
          <a className="navbar-brand" href="/">
            <img
              alt=""
              width="150"
              height="53"
              className="d-inline-block align-top img-fluid"
              src={logoImg}
            />
          </a>
        </div>
      </nav>

      {/*<!--Sign-In-->*/}

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md margin-signin-banner">
              <Lottie
                options={{ ...lottieOptions, animationData: signinAnimation }}
              />
            </div>
            <div className="col-md text-center text-md-start  mt-0 mt-md-5 pt-md-5">
              <span className="text-primary fw-bold fs-2">
                {" "}
                Acessar meu painel{" "}
              </span>
              <form>
                <div className="mb-1 mt-4 ">
                  <input
                    type="email"
                    className="form-control rounded-pill bg-secondary mb-2 ps-4 py-2"
                    id="email"
                    placeholder="E-mail"
                  />
                  <input
                    type="password"
                    className="form-control rounded-pill bg-secondary ps-4 py-2 mb-4"
                    id="password"
                    placeholder="Senha"
                  />

                  <button
                    type="submit"
                    className="btn btn-primary text-light rounded-pill px-md-2 py-2"
                  >
                    <span className="small px-3 px-md-5 text-proportion">
                      Entrar
                    </span>
                  </button>
                  <a
                    href="./cadastro"
                    className=" nounderline text-primary rounded-pill px-4 py-2 "
                  >
                    <span className="small text-proportion roboto fw-bold">
                      Crie sua conta grátis
                    </span>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <CookieAlert />

      <section>
        <div className="white-space  mt-5"></div>
      </section>
    </div>
  );
}
