import React from "react";
import Lottie from "react-lottie";

import logoImg from "../img/logo_emepag.svg";
import thankYouAnimation from "../animations/beach.json";
import Navbar from "../components/Navbar";
import Modals from "../components/Modals";

const lottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function postsignup() {
  return (
    <div>
      {/*<!--Navbar logo-->*/}
      <Modals/>
      <Navbar/>

      {/*<!--Navbar logo-->*/}

      <section className="p-1 p-lg-5">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <Lottie
                options={{ ...lottieOptions, animationData: thankYouAnimation }}
              />
            </div>
            <div className="col-md-5 text-center text-md-start d-flex flex-column">
              <span className="text-info fw-bold bigger-font mt-0 mt-md-5"> Uhuuu! </span>
              <span className="text-primary fw-bold fs-2"> Agora é só aguardar! </span>
              <span className="text-info mt-2 fw-bold fs-2"> Nós entraremos em contato logo, viu?</span>
              <div className="d-flex flex-column mt-4 text-proportion">
              <span className="roboto fw-bold even-smaller-font">
                Central de suporte
              </span>
              <span className="roboto even-smaller-font">
                WhatsApp (81) 99699-8508
              </span>
              <span className="roboto even-smaller-font">
                E-mail: suporte@emepag.com.br
              </span>
              <span className="roboto even-smaller-font">
                Segunda à sexta-feira, das 09h00 às 18h00 Sábado, das 09h00 às
                13h00
              </span>
            </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
