import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import logoImg from "../img/logo_emepag.svg";

export default function Navbar() {
  return (
    <nav className="z-index-navbar navbar navbar-expand-xl bg-white navbar-light px-2 px-lg-5 py-3 mb-2 mb-lg-5">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img
            alt=""
            width="150"
            height="53"
            className="d-inline-block align-top img-fluid"
            src={logoImg}
          />
        </a>
        <button
          className="navbar-toggler ms-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navmenu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navmenu"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a href="/#cases" className="nav-link  px-4 roboto text-black">
                Cases
              </a>
            </li>
            <li className="nav-item">
              <a href="/#fees" className="nav-link  px-4 roboto text-black">
                Taxas
              </a>
            </li>

            <li className="nav-item">
              <a href="/contato" className="nav-link  px-4 roboto text-black">
                Contato
              </a>
            </li>
            <li className="nav-item d-xl-none">
              <a
                href="https://painel.emepag.com.br/login"
                className="nav-link text-primary fw-bold px-4  roboto"
              >
                Entrar
              </a>
            </li>
            <li className="nav-item d-xl-none">
              <a
                href="/cadastro"
                className="btn btn-primary  rounded-pill px-4 mt-2"
              >
                Crie sua conta grátis
              </a>
            </li>
          </ul>
        </div>
        <div className="d-none d-xl-block align-items-end">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                href="https://painel.emepag.com.br/login"
                className="nav-link text-primary fw-bold px-5 roboto"
              >
                Entrar
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/cadastro"
                className="btn btn-primary  rounded-pill px-5 fs-6"
              >
                Crie sua conta grátis
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
   
  );
}
