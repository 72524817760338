import React from "react";
import Lottie from "react-lottie";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import logoImg from "../img/logo_emepag.svg";
import signupAnimation from "../animations/sign-up-banner.json";
import CookieAlert from "../components/CookieAlert";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Modals from "../components/Modals";

const lottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const SignupSchema = Yup.object().shape({
  valor1: Yup.string().required("Campo obrigatório"),
  valor2: Yup.string().required("Campo obrigatório").email("E-mail inválido"),
  valor3: Yup.string().required("Campo obrigatório"),
  valor4: Yup.string()
    .required("Campo obrigatório")
    .length(14, "Necessário 14 dígitos"),
  valor5: Yup.string()
    .required("Campo obrigatório")
    .min(2, "Mínimo de 2 dígitos")
    .max(2, "Máximo de 2 dígitos"),
  valor6: Yup.string()
    .required("Campo obrigatório")
    .min(8, "Mínimo de 8 dígitos")
    .max(9, "Máximo de 9 dígitos"),
  valor7: Yup.string().required("Campo obrigatório"),
  valor8: Yup.string()
});

export default function SignUp() {
  const navigate = useNavigate();

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      console.log(values, "values");
      const response = await axios.post(
        process.env.REACT_APP_ENV === "production"
          ? "https://api.emepag.com.br/v1/system/signup_form"
          : "https://api.emepag.com.br/v1/system/signup_form",
        values
      );
      navigate("/obrigado");
    } catch (error) {
      setSubmitting(false);
      console.log(error, "error");
      alert("Erro ao enviar cadastro, entre em contato pelo nosso WhatsApp (81) 99699-8508");
    }
  };
  return (
    <div className="hide-overflow">
      {/*<!--Modals-->*/}
      <Modals />

      {/*<!--Navbar logo-->*/}
      <Navbar />

      {/*<!--Sign-Up Banner-->*/}

      <section className="bg-size bg-secondary ">
        <div className="container px-2 px-lg-5 pt-lg-5 banner-top-margin ">
          <div className="row">
            <div
              className="
              
              col-md-5
              order-2 order-md-1
              
              
              text-center text-md-start
              py-4 py-md-3 py-lg-2
              signup-text
            "
            >
              <h2 className="text-primary ">
                Pronto para vender sem se preocupar com fraudes?
              </h2>
              <h2
                href="#signup"
                className="text-info  text-center  text-md-start mt-4"
              >
                Crie sua conta grátis!
              </h2>
            </div>
            <div className="col-md-7 order-1 order-md-2">
              <div className="position-signup-img img-fluid ">
                <Lottie
                  options={{ ...lottieOptions, animationData: signupAnimation }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!--Sign-Up Form-->*/}
      <section className="margin-form px-2 px-lg-5">
        <div className="container">
          <h2 className="text-primary text-center text-md-start mt-5 mb-5 mb-md-3 ">
            Informe seus dados
          </h2>
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              valor1: "",
              valor2: "",
              valor3: "",
              valor4: "",
              valor5: "",
              valor6: "",
              valor7: "",
              valor8: "Emepag",
            }}
            validationSchema={SignupSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3 me-md-2">
                      <label for="valor1" className="form-label small roboto ">
                        Seu nome
                      </label>
                      <Field
                        type="text"
                        className="form-control rounded-pill bg-secondary"
                        id="valor1"
                        name="valor1"
                      />
                      <ErrorMessage
                        name="valor1"
                        component="div"
                        className="error-style ms-2 mb-0 mb-lg-1"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3 ms-md-2">
                      <label for="valor2" className="form-label small roboto">
                        Seu e-mail
                      </label>
                      <Field
                        type="email"
                        className="form-control rounded-pill bg-secondary"
                        id="valor2"
                        name="valor2"
                      />
                      <ErrorMessage
                        name="valor2"
                        component="div"
                        className="error-style ms-2 mb-0 mb-lg-1"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3 me-md-2">
                      <label for="valor3" className="form-label small roboto ">
                        Nome da sua marca
                      </label>
                      <Field
                        type="text"
                        className="form-control rounded-pill bg-secondary"
                        id="valor3"
                        name="valor3"
                      />
                      <ErrorMessage
                        name="valor3"
                        component="div"
                        className="error-style ms-2 mb-0 mb-lg-1"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3 me-md-2">
                      <label for="valor4" className="form-label small roboto ">
                        CNPJ
                      </label>
                      <Field
                        type="number"
                        className="form-control rounded-pill bg-secondary"
                        id="valor4"
                        name="valor4"
                      />
                      <ErrorMessage
                        name="valor4"
                        component="div"
                        className="error-style ms-2 mb-0 mb-lg-1"
                      />
                    </div>
                  </div>

                  <div className="col-4 col-md-2">
                    <div className="mb-3 ">
                      <label for="valor5" className="form-label small roboto">
                        DDD
                      </label>
                      <Field
                        type="number"
                        className="form-control rounded-pill bg-secondary"
                        id="valor5"
                        name="valor5"
                      />
                      <ErrorMessage
                        name="valor5"
                        component="div"
                        className="error-style ms-2 mb-0 mb-lg-1"
                      />
                    </div>
                  </div>
                  <div className="col-8 col-md-4">
                    <div className="mb-3 me-md-2">
                      <label for="valor6" className="form-label small roboto">
                        Número
                      </label>
                      <Field
                        type="number"
                        className="form-control rounded-pill bg-secondary"
                        id="valor6"
                        name="valor6"
                      />
                      <ErrorMessage
                        name="valor6"
                        component="div"
                        className="error-style ms-2 mb-0 mb-lg-1"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3 ms-md-2">
                      <label for="valor7" className="form-label small roboto">
                        Quanto você vende por mês no cartão?
                      </label>
                      <Field
                        type="text"
                        className="form-control rounded-pill bg-secondary"
                        id="valor7"
                        name="valor7"
                      />
                      <ErrorMessage
                        name="valor7"
                        component="div"
                        className="error-style ms-2 mb-0 mb-lg-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="align-items-center d-flex justify-content-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary text-light rounded-pill px-md-3 py-2"
                    disabled={isSubmitting}
                  >
                    <span className="small px-3 px-md-5 text-proportion">
                      {isSubmitting
                        ? "Enviando"
                        : "Enviar meus dados para cadastro"}
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>

      <CookieAlert />

      {/*<!--Footer-->*/}

      <Footer />
    </div>
  );
}
